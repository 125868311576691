.PartnerAgencies {
    display: flex;
    justify-content: center;
    margin-top: 90px; 
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.PartnerAgencies__Subtitle {
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 0 20px;
    text-align: center;
}

.PartnerAgencies__Agencies {
    display: flex;
    justify-content: center;
    gap: 23px;
    flex-wrap: wrap;
}

.PartnerAgencies__Agencies__Box {
    width: 158px;
    height: 94px;
    border: 1px solid rgba(255, 255, 255, 0.17);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
}

.PartnerAgencies__Agencies__Box img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
}

@media screen and (min-width: 1200px) {
    .PartnerAgencies {
        margin-bottom: 109px;
    }
    .PartnerAgencies__Agencies__Box {
        width: 283px;
        height: 168px;
    }
    .PartnerAgencies__Subtitle {
        margin-bottom: 32px;
    }
}