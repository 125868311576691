.Head {
    width: 100%;
    height: min(838px, 70vw);
    position: relative;
}

.Head__Background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.Head__BackgroundTop {
    opacity: 0.6;
    background-color: #1F144D;
    z-index: 1;
}

.Head__BackgroundImage {
    background-image: url(../../assets/herobackground.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Head__Wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    align-content: space-around;
    flex-direction: column;
}

.Head__Content {
    width: min(923px, 80vw);
}

.Head__LogoContainer {
    margin-top: min(5vw, 60px);
}

.Head__LogoContainer:hover {
    cursor: pointer;
    opacity: 0.9;
}

.Head__SupTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #fff; 
    text-transform: uppercase;
    margin-bottom: 15px;
}

@media screen and (min-width: 700px) {
    .Head__SupTitle {
        font-size: 28px;
    }
}

.Head__ArrowContainer{
    margin-bottom: 60px;
    animation-name: bounce;
    animation-duration: 0.5s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
    animation-iteration-count: infinite;
}

.Head__ArrowContainer:hover{
    cursor: pointer;
    animation-name: none;
}

@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, 30px, 0);
    }
}