.Brands {
    /* height: 201px; */
    background-color: var(--brand-100);
    display: flex;
    flex-direction: column;
}

.Brands__Title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.275em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 38px;
}
.Brands__Content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 32px;
    align-items: center;
    flex-wrap: wrap;
    transform: scale(0.7);
    width: 120%;
    margin: auto;
    margin-left: -9%;
}

@media screen and (min-width: 700px) {
    .Brands__Content {
        transform: scale(1);
        width: 97%;
        margin: auto;
        margin-top: 30px;
        padding-bottom: 30px;
        margin-left: auto;
    }
}