.Footer {
    width: 100%;
    background-color: var(--black-90);
    height: 355px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.Footer__Text {
    color: var(--white-40);
    width: min(737px, 90vw);
    text-align: center;
    margin-top: 8px;
}

.Footer__Button {
    width: 252px;
    margin: 24px;
}

.Footer__Bottom {
    height: 110px;
    width: 100%;
    background-color: var(--black-70);
}

.Footer__Bottom__Wrapper {
    width: min(var(--central-column-width), 90vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    height: 100%;
}

.Footer__Bottom__SocialContainer svg {
    margin-right: 15px;
}

.Footer__Bottom__Wrapper svg:hover {
    cursor: pointer;
    opacity: 0.9;
}