:root {
    --brand-0: #fff;
    --brand-20: #E1D9FF;
    --brand-40: #C3B3FF;
    --brand-60: #A48EFF;
    --brand-70: #957BFF;
    --brand-80: #8668FF;
    --brand-100: #6842FF;
    --brand-120: #5335CC;
    --brand-140: #3E2899;
    --brand-160: #2A1A66;
    --brand-180: #150D33;
    --brand-200: #07050F;
    --black-10: #474967;
    --black-20: #434561;
    --black-40: #373952;
    --black-50: #2F3148;
    --black-60: #28293D;
    --black-70: #212233;
    --black-80: #1A1B28;
    --black-90: #13141E;
    --black-100: #0C0D14;
    --white-10: #666A7F;
    --white-20: #767A8E;
    --white-30: #878A9E;
    --white-40: #989BAE;
    --white-50: #AAADBE;
    --white-60: #BDBFCE;
    --white-80: #E5E6EE;
    --white-90: #EFF0F7;
    --white-100: #F9FAFF;
    --success-100: #4AF0A7;
    --alert-100: #E70D5C;
    --warning-100: #FFAC4A;
}