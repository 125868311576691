.GenZUsers {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 104px;
    width: min(1200px, 90vw);
    flex-wrap: wrap;
}

.GenZUsers img {
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    object-fit: cover;
}

.GenZUsers__RightContainer {
    width: 100%;
    background-color: var(--black-90);
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
}

@media screen and (min-width: 1200px) {
    .GenZUsers__RightContainer {
      padding: 90px
    }
}

@media screen and (min-width: 700px) {

    .GenZUsers img {
        width: 50%;
        border-top-right-radius: 0;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
    }
    .GenZUsers__RightContainer {
        width: 50%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
    }
}

.GenZUsers__RightContainer__Title {
    margin-bottom: 20px;
}