@import "colors.css";

:root {
  --central-column-width: 1196px;
}

body {
  margin: 0;
  background-color: var(--black-100);
  font-family: 'Nunito';
  scrollbar-width: thin;
  scrollbar-color: var(--white-50);
}

body::-webkit-scrollbar {
  width: 4px;
  height: 100%;
  background: 'rgba(0,0,0,0)'
}

body::-webkit-scrollbar-thumb {
  background: var(--white-50);
  border-radius: 30px;
}

body::-webkit-scrollbar-corner {
  background: 'rgba(0,0,0,0)'
}

h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 100%;
  color: #fff;
  margin-block: 0;
}

h2 {
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #fff;
  margin-block: 0;
}

h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #FFF; 
  margin-block: 0;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--white-50);
  margin-block: 0;
}

@media screen and (min-width: 700px) {
  h1 {
    font-size: 40px;
  }
}

@media screen and (min-width: 1200px) {
  h1 {
    font-size: 80px;
  }
  p {
    font-size: 20px;
  }
  h2 {
    font-size: 48px;
  }
  h3 {
    font-size: 28px;
  }
}

button {
  background: var(--brand-100);
  border-radius: 30px;
  border: none;
  color: #FFF;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 130%;
  height: 50px;
}

button:hover {
  cursor: pointer;
  background: var(--brand-120);
}

button:active {
  background: var(--brand-140);
}