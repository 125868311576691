.Body {
    display: flex;
    gap: 30px;
    width: min(var(--central-column-width), 90vw);
    margin: auto;
    position: relative;
    margin-top: 30px;
    flex-direction: column;
}

.Body__Button {
    width: 178px;
}

.Body__LeftContainer{
    width: 100%;
    padding-left: 20px;
}
.Body__RightContainer{
    width: 100%;
}

.Body__LeftContainer__Content{
    list-style: none;
    padding: 0;
    margin-top: 32px;
}

.Body__LeftContainer__Content li {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.Body__LeftContainer__Content li svg{
    margin-right: 12px;
    width: 30px;
}



.Body__LeftContainer__Content__Button {
    margin-top: 20px;
}

.Body__RightContainer__Box {
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 20px; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 10px;
    margin-bottom: 18px;
}

.Body__RightContainer__Box__Head{
    display: flex;
    align-items: center;
}

.Body__RightContainer__Box__Head svg {
    margin-right: 15px;
    width: 25px;
    height: 25px;
}

@media screen and (min-width: 700px) {
    .Body {
        flex-direction: row;
        margin-top: 107px;
        gap: 62px;
    }
    .Body__LeftContainer{
        width: 48%;
        padding-left: 0;
    }
    .Body__RightContainer{
        width: 52%;
    }
    .Body__LeftContainer__Content li {
        margin-bottom: 12px;
    }
    .Body__LeftContainer__Content li svg{
        margin-right: 24px;
        width: 40px;
        height: 40px;
    }
    .Body__RightContainer__Box__Head svg {
        width: 50px;
        height: 50px;
    }
}